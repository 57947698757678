









































































































import { Component, Vue, Prop } from 'vue-property-decorator';
// eslint-disable-next-line
// @ts-ignore
import { FullBgCard, InfoAreas } from '@/client/components/_theme';
import { ImageStrings } from '@/server/types';

@Component({
  components: {
    FullBgCard,
    InfoAreas,
  },
})
export default class PassionsSectionHome extends Vue {
  /* eslint-disable */
  @Prop({ default: false })
  readonly noBg!: boolean;

  currentIndex = 0;
  cardAbout: ImageStrings = {
    image1: require('@/client/assets/images/microscape-desktop.jpg'),
    image2: require('@/client/assets/images/blue-velvet-shrimp.jpg'),
    image3: require('@/client/assets/images/medium-discus.jpg'),
    image4: require('@/client/assets/images/desktop-nanoscape.jpg'),
  };

  updateIndex = () => {
    const index = this.currentIndex;
    const newIndex = index + 1;

    this.currentIndex = newIndex;

    return newIndex;
  }
}
