








/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import CitySection from '@/client/components/CitySection.vue';
import Contact from '@/client/components/Contact.vue';
import GetInTouch from '@/client/components/GetInTouch.vue';
import PassionsSection from '@/client/components/PassionsSection.vue';
import BasicHeader from '@/client/components/core/BasicHeader.vue';
// @ts-ignore
import { FullBgCard } from '@/client/components/_theme';
import { ImageStrings } from '@/server/types';
import * as Constants from '@/client/components/constants';

@Component({
  components: {
    CitySection,
    FullBgCard,
    Contact,
    GetInTouch,
    BasicHeader,
    PassionsSection,
  },
})
export default class Microscapes extends Vue {
  Constants = Constants;
  image: string = require('@/client/assets/images/microscape-desktop.jpg');
  images: any =  {
    passionImages: {
      image1: require('@/client/assets/images/terra-bowl-2.jpg'),
      image2: require('@/client/assets/images/desktop-nanoscape.jpg'),
      image3: require('@/client/assets/images/small-forest-aquascape.jpg'),
    },
  };
}
