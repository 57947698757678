








/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import { ImageStrings } from '@/server/types';
import ParallaxHeader from '@/client/components/ParallaxHeader.vue';
import PassionsSection from '@/client/components/PassionsSection.vue';
import GetInTouch from '@/client/components/GetInTouch.vue';
import api from '../util/api';
// @ts-ignore
import { ProfileCard, InfoAreas, Parallax, FullBgCard } from '@/client/components/_theme';
import Contact from '@/client/components/Contact.vue';
import * as Constants from '@/client/components/constants';

@Component({
  components: {
    ProfileCard,
    InfoAreas,
    FullBgCard,
    Contact,
    ParallaxHeader,
    PassionsSection,
    GetInTouch,
  },
})
export default class MainNavbar extends Vue {
  Constants = Constants;
  image: string = require('@/client/assets/images/aquascape-3.jpg');
  images: any =  {
    passionImages: {
      image1: require('@/client/assets/images/cardinal-sulawesi-shrimp.jpg'),
      image2: require('@/client/assets/images/aquascape-2-small.jpg'),
      image3: require('@/client/assets/images/discus.jpg'),
    },
  };

  // eslint-disable-next-line
  sectionBackground(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }
}
