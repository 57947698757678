































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MainFooter extends Vue {
  @Prop()
  readonly backgroundColor!: string;

  @Prop()
  readonly type!: string;

  @Prop()
  readonly size!: string;

  year: number = new Date().getFullYear();
}
