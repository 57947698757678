





































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import RestStatus from '@/client/components/core/RestStatus.vue';
import Alert from '@/client/components/core/Alert.vue';
import { ServerResponse } from '@/server/types';
import api from '../util/api';

@Component({
  components: {
    Alert,
    RestStatus,
  },
})
export default class Contact extends Vue {
  /* eslint-disable no-prototype-builtins */
  // eslint-disable-next-line
  image: string = require('@/client/assets/images/microscape-desktop.jpg');
  name = '';
  email = '';
  message = '';
  boolean = false;
  loading = false;
  error = false;
  responseType = '';
  alertMessage = '';
  showAlert = false;

  resetForm() {
    this.message = '';
    this.name = '';
    this.email = '';
    this.boolean = false;
  }

  resetAlert() {
    this.alertMessage = '';
    this.responseType = '';
    this.showAlert = false;
  }

  popAlert(response: ServerResponse) {
    this.responseType = response.toString().includes('failed') ? 'danger' : 'success';
    this.alertMessage = response.hasOwnProperty('message') ? response.message : response.toString();
    this.showAlert = true;
  }

  submitLead() {
    this.resetAlert();
    this.loading = true;

    const lead = {
      name: this.name,
      email: this.email,
      message: this.message,
    };

    api('POST', 'https://rarequaticfinds.herokuapp.com/api/leads/question', lead)
      .then((response: ServerResponse) => {
        console.log(response);
        this.popAlert(response);
        this.loading = false;
        this.resetForm();
      })
      .catch((err) => {
        this.popAlert(err);
        this.loading = false;
        this.error = true;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  sectionBackground(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }
}
