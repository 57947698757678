
































































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import RestStatus from '@/client/components/core/RestStatus.vue';
import Alert from '@/client/components/core/Alert.vue';
import {
  InfoArea,
  ServerResponse,
  GetStartReturn,
  PurchaseLead,
} from '@/server/types';
import * as Constants from '@/client/components/constants';
import api from '../util/api';
// @ts-ignore
import { InfoAreas, Tabs, Parallax } from './_theme';

@Component({
  components: {
    Alert,
    InfoAreas,
    Tabs,
    Parallax,
    RestStatus,
  },
})
export default class GetStarted extends Vue {
  Constants = Constants;

  boolean = false;
  year: number = new Date().getFullYear();
  name = '';
  email = '';
  location = '';
  alertMessage = '';
  responseType = '';
  showAlert = false;
  loading = false;
  error = false;
  specialty = '';
  type = '';
  grade = '';
  price = '';
  headerImage = '';
  title = 'Get Started';
  subtitle = 'Let us know what you are looking for and we will help you get it!';
  quantity = 0;
  outOfStock = true;
  initialConfig = true;
  quantities = [5, 10, 15, 20];

  // These have to live in the class due to the way the bundler packages the files, bummer
  headerImageLookup: any = {
    harlequin: require('@/client/assets/images/harlequin-sulawesi-shrimp.jpg'),
    cardinal: require('@/client/assets/images/cardinal-sulawesi-shrimp.jpg'),
    crystal: require('@/client/assets/images/crystal-red-shrimp.jpg'),
    cherry: require('@/client/assets/images/red-cherry-shrimp.jpg'),
    'blue velvet': require('@/client/assets/images/blue-velvet-shrimp.jpg'),
    'red rili': require('@/client/assets/images/red-rili-shrimp.jpg'),
    'show grade': require('@/client/assets/images/show-discus.jpg'),
    'personal tank': require('@/client/assets/images/medium-discus.jpg'),
    wholesale: require('@/client/assets/images/common-discus.jpg'),
    'entry level': require('@/client/assets/images/aquascape-2-small.jpg'),
    'discus scape': require('@/client/assets/images/discus.jpg'),
    'nature aquascape': require('@/client/assets/images/aquascape-3.jpg'),
    bowls: require('@/client/assets/images/desktop-nanoscape.jpg'),
    ponds: require('@/client/assets/images/microscape-desktop.jpg'),
    natural: require('@/client/assets/images/desktop-nanoscape.jpg'),
  };

  contentLeft: Array<InfoArea> = [
    {
      colorIcon: 'success',
      icon: 'request_quote',
      title: 'Free Quote',
      description:
        'Get a no hassle quote today, we will find you the best prices for your area.',
    },
    {
      colorIcon: 'info',
      icon: 'speed',
      title: 'Responsive',
      description:
        "Don't like to wait? Neither do we. We'll get back to you within 24 hours.",
    },
  ];

  get outOfStockMessage() {
    if (!this.finalPrice) {
      return 'You are just one step away';
    }

    return `Due to the massive amount of demand, we are currently out of ${this.title}. We restock quickly, leave us your info and we'll let you know as soon as they are available.`;
  }

  get types() {
    return this.specialty ? Constants.typesLookup[this.specialty].values : undefined;
  }

  get currentType() {
    return this.type ? Constants.specialtyLookup[this.type] : undefined;
  }

  get grades() {
    const cType = this.currentType ? this.currentType : undefined;
    return cType && cType.grades ? cType.grades : undefined;
  }

  get finalPrice() {
    if (this.grades && this.grade) {
      return this.grades[this.grade].price * this.quantity;
    }

    return this.currentType ? this.currentType.price : undefined;
  }

  get isFormValidated() {
    return this.validateForm();
  }

  configureHeader() {
    let headerInfo;

    if (this.initialConfig) {
      const subtype = `${this.$route.params.subtype}`;
      headerInfo = Constants.specialtyLookup[subtype];
      this.headerImage = this.headerImageLookup[subtype] || require('@/client/assets/images/aquascape-1.jpg');
    } else {
      headerInfo = Constants.specialtyLookup[this.type];
      this.headerImage = this.headerImageLookup[this.type] || require('@/client/assets/images/aquascape-1.jpg');
    }

    this.initialConfig = false;

    if (headerInfo) {
      this.title = headerInfo.title;
      this.subtitle = headerInfo.subtitle;
      this.specialty = headerInfo.specialty;
      this.type = headerInfo.type;
    }
  }

  resetFields() {
    const currentSpecialty = this.specialty;
    const currentType = this.type;
    const reset = () => {
      this.configureHeader();
      this.grade = '';
      this.quantity = 0;
    }
    const softReset = () => {
      const spec = Constants.specialtyLookup[this.type];
      this.headerImage = this.headerImageLookup[this.type];
      this.title =  spec ? spec.title : 'Get Started';
      this.subtitle = spec ?  spec.subtitle : 'Let us know what you are looking for and we will help you get it!';
      reset();
    }

    const hardReset = () => {
      this.type = '';
      this.headerImage = require('@/client/assets/images/aquascape-1.jpg');
      this.title = 'Get Started';
      this.subtitle = 'Let us know what you are looking for and we will help you get it!';
      reset();
    }

    setTimeout(() => {
      if (currentSpecialty !== this.specialty) {
        hardReset();
      }
      if (currentType !== this.type) {
        softReset();
      }
    }, 250);
  }

  sectionBackground(image: string) {
    return {
      backgroundImage: `url(@${image})`,
    };
  }

  resetForm() {
    this.name = '';
    this.email = '';
    this.location = '';
  }

  resetAlert() {
    this.alertMessage = '';
    this.responseType = '';
    this.showAlert = false;
  }

  popAlert(response: string, message: string) {
    console.log(response, message);
    this.responseType = response;
    this.alertMessage = message;
    this.showAlert = true;
    window.scrollTo(0, document.body.scrollHeight);
  }

  validateEmail(email: string) {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateForm(): boolean {
    if (this.name.length > 3) {
      if (this.validateEmail(this.email) && this.boolean) {
        return true;
      }
    }

    return false;
  }

  submitLead() {
    this.resetAlert();

    const lead: PurchaseLead = {
      name: this.name,
      email: this.email,
    };

    if (this.type) {
      lead.type = this.type;
    }

    if (this.grade) {
      lead.grade = this.grade;
    }

    if (this.quantity) {
      lead.quantity = this.quantity;
    }

    api('POST', 'https://rarequaticfinds.herokuapp.com/api/leads/main', lead)
      .then((response: ServerResponse) => {
        console.log(response);
        this.loading = false;
        this.resetForm();
        this.popAlert('success', 'Message has been received, we will be in touch shortly.');
      })
      .catch((err) => {
        this.error = true;
        this.popAlert('danger', 'Unable to submit form, please try again later.');
        throw err;
      });
  }

  mounted() {
    this.configureHeader();
  }
}
