export const discusContent = {
  header: {
    title: 'Discus',
    subTitle: 'About our Discus',
    text: `
      As most enthusiasts understand, raising Discus cichlids are not easy!
      They require very specific and steady water conditions to survive and
      thrive. We take this very seriously! In our fish room, we have a Reverse
      Osmosis (R/O) filtration system set up to ensure only the most pristine
      water conditions, then we carefully add back the required minerals so
      that our Discus can thrive. Water changes are done consistently and all
      of our Discus are kept in pristine conditions. Soon you will be able to
      select your Discus through one of our live feeds!
    `,
  },
  passionSections: [
    {
      title: 'Some of our passions include',
      passions: [
        {
          title: 'Show Grade',
          text: `
            We have a personal stock of show placing Discus, as well as offspring from the show winners,
            both mixed with other show winners, or pure bred for the same colorations and styles. These guys are
            guaranteed to do well at local shows, as well as more large scale shows.
          `,
          cardText: 'Show Ready',
          buttonText: 'Show Ready Discus',
          routerConfig: {
            title: 'Show Ready Discus',
            subtype: 'show grade',
            text: 'Selection from our personal show winning pairs',
          },
        },
        {
          title: 'Personal Tank Beauties',
          text: `
            Most of the Discus in this tank are direct descendents of show winners, usually pairs of show winner
            offspring from two different strains. These guys are absolutely breathtaking are and likely to do well
            at local shows. They will be the centerpiece of your tank to showcase for years to come.
          `,
          cardText: 'Centerpiece Collection',
          buttonText: 'Personal Beauties',
          routerConfig: {
            title: 'Personal Tank Beauties',
            subtype: 'personal tank',
            text: 'High quality from show winning offspring',
          },
        },
        {
          title: 'Discus for Wholesalers',
          text: `
            We also have a large selection of common Discus, only of the highest quality of course! These guys are
            guaranteed to show well in your shop, or your personal tanks for show.
          `,
          cardText: 'Quality for Cost',
          buttonText: 'Browse Common Discus',
          routerConfig: {
            title: 'Beautiful Discus at Wholesale Prices',
            subtype: 'wholesale',
            text: 'Selection of high quality common Discus',
          },
        },
      ],
      fullImageBgSection: {
        title: 'We believe the key to show grade Discus is a natural environment.',
        text: `
          We choose plants that are natural to any of our livestock when planting an aquarium.
          Almost every one of our breeding tanks is planted or aquascaped to help provide a
          natural and stress free environment.
        `,
      },
    },
  ],
} as const;

export const aquascapeContent = {
  header: {
    title: 'Aquascapes',
    subTitle: 'The Art of Aquascaping',
    text: `
      Aquascaping in itself can be incredibly challenging yet rewarding. Aquascaping for large
      spaces in a whole new challenge in itself. Each scape has to be carefully planned to be almost
      completely self sustained. These pieces can make a dramatic statement in large rooms.
    `,
  },
  passionSections: [
    {
      title: 'Some of our kits include',
      passions: [
        {
          title: 'Entry Level Aquascapes',
          text: `
            Let's be real, planning a new aquascape can be a daunting task. We have taken the time to come up with some
            simple aquascapes that are easy to maintain and grow, and looks beautiful without having to purchase specialized
            equipment. These sets are perfect for those who are new to the hobby!
          `,
          cardText: 'Entry Level Scapes',
          buttonText: 'Entry Level Aquascapes',
          routerConfig: {
            title: 'Entry Level Scapes',
            subtype: 'entry level',
            text: 'Aquascapes setups perfect for beginners',
          },
        },
        {
          title: 'Planted Setups for Discus',
          text: `
            Discus tanks can make quite a statement in any space, now add some proper aquascaping and you will be mesmerized for
            years to come. All of our discus aquascaping sets are carefully planned out with your discus in mind to ensure that
            your discus stay happy and healthy.
          `,
          cardText: 'Discus Aquascapes',
          buttonText: 'Discus Aquascapes',
          routerConfig: {
            title: 'Discus Aquascapes',
            subtype: 'discus scape',
            text: 'Simple, minimal, and beautiful aquascapes for Discus aquariums',
          },
        },
        {
          title: 'Slice of Nature Setups',
          text: `
            Imagine having a literal slice of nature sitting in one of our rooms. With advance aquascaping techniques this can be
            achieved. Whether it be a creakbed scene, or walk through the forest we can capture almost any piece of nature you desire.
          `,
          cardText: 'Natural Scenery',
          buttonText: 'Nature Setups',
          routerConfig: {
            title: 'Nature Aquascapes',
            subtype: 'nature aquascape',
            text: 'Aquascapes for those who love nature',
          },
        },
      ],
      fullImageBgSection: {
        title: 'We believe that every aquascape both large and small is a work of art.',
        text: `
          Countless hours go into the planning and preparation for for any scape. You have to make sure that
          all proper nutrients and substrate are used, and that the right balance of plants and rocks are selected
          for the hardscape. Determining the layout can be quite a challenge in itself as each setup is completely unique
          in it's own ways. We take pride in every one of our aquascapes and we want to help you recreate these beautiful
          scenes at home.
        `,
      },
    },
  ],
} as const;

export const microscapeContent = {
  header: {
    title: 'Microscapes',
    subTitle: 'The Art of Aquascaping in Small Spaces',
    text: `
      Aquascaping in itself can be incredibly challenging yet rewarding. Aquascaping for small
      spaces in a whole new challenge in itself. Each scape has to be carefully planned to be almost
      completely self sustained. These pieces can make a dramatic statement in smaller spaces.
    `,
  },
  passionSections: [
    {
      title: 'Some of our kits include',
      passions: [
        {
          title: 'Nature Bowls',
          text: `
            Terrariums come in all tastes, the possibilities of how a terrarium can look are endless! The beauty of these
            small ecosystems is that they are mostly self sustained after being set up, meaning you can enjoy them as a nice
            desktop or bookshelf decoration for years to come. Literally whatever look you are going for, we can achieve it.
          `,
          cardText: 'Nature Bowl',
          buttonText: 'Bowls',
          routerConfig: {
            title: 'Nature Bowls',
            subtype: 'bowls',
            text: 'Microscapes for those who love nature',
          },
        },
        {
          title: 'Desktop Ponds',
          text: `
            Desktop ponds are some of the most beautiful decorations you can have for small spaces. They are definite
            conversation pieces and will have you and your guests staring in awe. These setups are very small and typically
            low maintenence. They are perfect for those who want to bring the delights of a pond to their desktops.
          `,
          cardText: 'Micro Ponds',
          buttonText: 'Desktop Ponds',
          routerConfig: {
            title: 'Desktop Pond',
            subtype: 'ponds',
            text: 'A small pond to enjoy in any area',
          },
        },
        {
          title: 'Slice of Nature Setups',
          text: `
            Imagine having a literal slice of nature sitting in one of our rooms. With advance aquascaping techniques this can be
            achieved. Whether it be a creakbed scene, or walk through the forest we can capture almost any piece of nature you desire.
          `,
          cardText: 'Natural Scenery',
          buttonText: 'Nature Setups',
          routerConfig: {
            title: 'Natural Microscape',
            subtype: 'natural',
            text: 'Microscapes for those who love nature',
          },
        },
      ],
      fullImageBgSection: {
        title: 'We believe that every aquascape both large and small is a work of art.',
        text: `
          Countless hours go into the planning and preparation for for any scape. You have to make sure that
          all proper nutrients and substrate are used, and that the right balance of plants and rocks are selected
          for the hardscape. Determining the layout can be quite a challenge in itself as each setup is completely unique
          in it's own ways. We take pride in every one of our aquascapes and we want to help you recreate these beautiful
          scenes at home.
        `,
      },
    },
  ],
} as const;

export const shrimpContent = {
  header: {
    title: 'Shrimp',
    subTitle: 'About our Shrimp',
    text: `
      As most enthusiasts understand, shrimp can be very delicate and sensitive beings! They require very specific and steady
      water conditions to survive and thrive. We take this very seriously! In our fish room, we have a Reverse
      Osmosis (R/O) filtration system set up to ensure only the most pristine water conditions, then we carefully
      add back the required minerals so that our Discus can thrive. Water changes are done consistently and all of
      our shrimp are kept in pristine conditions. Soon you will be able to select your shrimp through one of our
      live feeds!
    `,
  },
  passionSections: [
    {
      title: 'Some of our Caridina include',
      passions: [
        {
          title: 'Bee shrimp (Crystals)',
          text: `
            Bee shrimp are in demand as aquarium pets. In Taiwan and other areas with suitable climates shrimp farms
            have sprung up to supply Bee shrimp, although they are more difficult to farm than other shrimp because their
            health depends on being raised in soft water that matches the pH of their native streams.
          `,
          cardText: 'Bee Shrimp',
          buttonText: 'Bee Shrimp',
          routerConfig: {
            title: 'Crystal Shrimp',
            subtype: 'crystal',
            text: 'A to SSS Grade',
          },
        },
        {
          title: 'Cardinal Sulawesi',
          text: `
            Caridina dennerli is a small species of freshwater shrimp from Sulawesi (Indonesia) that grows up to 2.5
            centimetres (1.0 in) in length. It takes its name from the German company Dennerle, which supported the
            expedition that led to the scientific description of the species.[2] It is popularly known as the 'Cardinal Shrimp'
            in the aquarium trade.
          `,
          cardText: 'Cardinal Sulawesi',
          buttonText: 'Cardinal Sulawesi',
          routerConfig: {
            title: 'Cardinal Shrimp',
            subtype: 'cardinal',
            text: 'A to SSS Grade',
          },
        },
        {
          title: 'Harlequin Shrimp',
          text: `
            Caridina spongicola is a small species of freshwater shrimp from Sulawesi that reaches 0.64 to 1.27 cm in length.
            In the wild it strictly lives on an undescribed species of freshwater sponge, making it one of only two known
            commensal species of freshwater shrimp.
          `,
          cardText: 'Harlequin Shrimp',
          buttonText: 'Harlequin Shrimp',
          routerConfig: {
            title: 'Harlequin Shrimp',
            subtype: 'harlequin',
            text: 'A to SSS Grade',
          },
        },
      ],
      fullImageBgSection: {
        title: 'Caridina',
        text: `
          We have a nice selection of Caridina shrimp that are perfect for shrimp keeping enthusiasts and
          experienced breeders.
        `,
      },
    },
    {
      title: 'Some of our Neocaridina include',
      passions: [
        {
          title: 'Cherry Shrimp',
          text: `
            Neocaridina davidi is a freshwater shrimp from Taiwan which is commonly kept in aquaria. The natural coloration
            of the shrimp is green-brown. There are a wide range of colors such as red, yellow, orange, green, blue, violet,
            black, etc., however, the red morph is more frequently sold.
          `,
          cardText: 'Cherry Shrimp',
          buttonText: 'Cherry Shrimp',
          routerConfig: {
            title: 'Red Cherry Shrimp',
            subtype: 'cherry',
            text: 'A to SSS Grade',
          },
        },
        {
          title: 'Blue Velvet Shrimp',
          text: `
            Bred from the same wild type as Sakura Red Cherry Shrimp, the Dream Blue Velvet Shrimp is a color variant of the
            Neocaridina davidi (formerly Neocaridina heteropoda) species. They breed very quickly, forming a colony that works
            as a very effective cleaning crew and enhances any aquarium with their beautiful color.
          `,
          cardText: 'Blue Velvets',
          buttonText: 'Blue Velvet Shrimp',
          routerConfig: {
            title: 'Blue Velvet Shrimp',
            subtype: 'blue velvet',
            text: 'A to SSS Grade',
          },
        },
        {
          title: 'Red Rili Shrimp',
          text: `
            Red Rili Shrimp belong to the same species as Sakura Red Cherry Shrimp (Neocaridina davidi), so their care
            requirements are essentially the same.  All Red Rili Shrimp are extremely adaptable creatures that can live in
            almost any freshwater aquarium. These are freshwater shrimp and can live in almost any freshwater aquarium. They
            are very popular for planted tanks and community tanks, and many people use them in large aquariums for waste
            management and algae control.
          `,
          cardText: 'Red Rilis',
          buttonText: 'Red Rili Shrimp',
          routerConfig: {
            title: 'Red Rili Shrimp',
            subtype: 'red rili',
            text: 'A to SSS Grade',
          },
        },
      ],
      fullImageBgSection: {
        title: 'Neocaridina',
        text: `
          We have a nice selection of Neoaridina shrimp that are perfect for shrimp keepers of all skill levels. These guys
          will bring life and color to any community tank and are typically easy to care for.
        `,
      },
    },
  ],
} as const;

export const aboutContent = {
  header: {
    title: 'Our Story',
    subTitle: 'What started as a hobby and passion turned into a business',
    text: `
      Ten years ago, I started my first serious tank. As a beginner,
      I made some mistakes and underestimated the costs, but that never
      discouraged me from continuing. Over the years I accumulated more
      tanks, I started aquascaping as well as breeding rare fish. Today
      I am the proud owner of many rare and healthy fish, some of which
      include zebra plecos, flowerhorn cichlids, and a variety of discus
      cichlids.
    `,
  },
  passionSections: [
    {
      title: 'Some of our passions include',
      passions: [
        {
          title: 'Rare Shrimp Breeding',
          text: `
            As most enthusiasts understand, shrimp can be very delicate and sensitive beings! They require very specific and steady
            water conditions to survive and thrive. We take this very seriously! In our fish room, we have a Reverse
            Osmosis (R/O) filtration system set up to ensure only the most pristine water conditions, then we carefully
            add back the required minerals so that our Discus can thrive.
          `,
          cardText: 'Rare Shrimp',
          buttonText: 'Learn More',
          url: '/specialties/shrimp',
        },
        {
          title: 'Aquascapes',
          text: `
            Countless hours go into the planning and preparation for for any scape. You have to make sure that
            all proper nutrients and substrate are used, and that the right balance of plants and rocks are selected
            for the hardscape. Determining the layout can be quite a challenge in itself as each setup is completely unique
            in it's own ways.
          `,
          cardText: 'Aquascapes',
          buttonText: 'Learn More',
          url: '/specialties/aquascapes',
        },
        {
          title: 'Discus Breeding',
          text: `
            As most enthusiasts understand, raising Discus cichlids are not easy!
            They require very specific and steady water conditions to survive and
            thrive. We take this very seriously! In our fish room, we have a Reverse
            Osmosis (R/O) filtration system set up to ensure only the most pristine
            water conditions, then we carefully add back the required minerals so
            that our Discus can thrive.
          `,
          cardText: 'Specialty Discus',
          buttonText: 'Learn More',
          url: '/specialties/discus',
        },
      ],
      fullImageBgSection: {
        title: 'We take pride in everything that we do',
        text: `
          So much so that if you are not satisfied with your purchase we will give you a full refund, no questions asked.
        `,
      },
    },
  ],
} as const;

export const typesLookup: any = {
  shrimp: {
    name: 'Shrimp',
    path: '/specialties/shrimp',
    values: [
      'Crystal',
      'Cardinal',
      'Harlequin',
      'Cherry',
      'Blue Velvet',
      'Red Rili',
    ],
  },
  discus: {
    name: 'Discus',
    path: '/specialties/discus',
    values: [
      'Show Grade',
      'Personal Tank',
      'Wholesale',
    ],
  },
  aquascapes: {
    name: 'Aquascapes',
    path: '/specialties/aquascapes',
    values: [
      'Entry Level',
      'Discus Scape',
      'Nature Scape',
    ],
  },

  microscapes: {
    name: 'Microscapes',
    path: '/specialties/microscapes',
    values: [
      'Bowls',
      'Ponds',
      'Natural',
    ],
  },
} as const;

export const specialtyLookup: any = {
  harlequin: {
    image: '@/client/assets/images/harlequin-sulawesi-shrimp.jpg',
    title: 'Harlequin Sulawesi Shrimp',
    subtitle: 'A to SS grade',
    type: 'harlequin',
    specialty: 'shrimp',
    grades: {
      a: {
        name: 'A',
        price: 6,
      },
      s: {
        name: 'S',
        price: 9,
      },
      ss: {
        name: 'SS',
        price: 12,
      },
    },
  },
  cardinal: {
    image: '@/client/assets/images/cardinal-sulawesi-shrimp.jpg',
    title: 'Cardinal Sulawesi Shrimp',
    subtitle: 'High grade Sulawesi strains for enthusiasts',
    type: 'cardinal',
    specialty: 'shrimp',
    grades: {
      a: {
        name: 'A',
        price: 6,
      },
      s: {
        name: 'S',
        price: 9,
      },
      ss: {
        name: 'SS',
        price: 12,
      },
    },
  },
  crystal: {
    image: '@/client/assets/images/crystal-red-shrimp.jpg',
    title: 'Crystal Shrimp (Black and Red)',
    subtitle: 'A to SS grade',
    type: 'crystal',
    specialty: 'shrimp',
    grades: {
      a: {
        name: 'A',
        price: 6,
      },
      s: {
        name: 'S',
        price: 9,
      },
      ss: {
        name: 'SS',
        price: 12,
      },
    },
  },
  cherry: {
    image: '@/client/assets/images/red-cherry-shrimp.jpg',
    title: 'Red Cherry Shrimp',
    subtitle: 'Perfect for beginner shrimp keepers',
    type: 'cherry',
    specialty: 'shrimp',
    grades: {
      a: {
        name: 'A',
        price: 6,
      },
      s: {
        name: 'S',
        price: 9,
      },
      ss: {
        name: 'SS',
        price: 12,
      },
    },
  },
  'blue velvet': {
    image: '@/client/assets/images/blue-velvet-shrimp.jpg',
    title: 'Blue Velvet Shrimp',
    subtitle: 'Perfect for beginner shrimp keepers',
    type: 'blue velvet',
    specialty: 'shrimp',
    grades: {
      a: {
        name: 'A',
        price: 6,
      },
      s: {
        name: 'S',
        price: 9,
      },
      ss: {
        name: 'SS',
        price: 12,
      },
    },
  },
  'red rili': {
    image: '@/client/assets/images/red-rili-shrimp.jpg',
    title: 'Red Rili Shrimp',
    subtitle: 'A to SS grade',
    type: 'red rili',
    specialty: 'shrimp',
    grades: {
      a: {
        name: 'A',
        price: 6,
      },
      s: {
        name: 'S',
        price: 9,
      },
      ss: {
        name: 'SS',
        price: 12,
      },
    },
  },
  'show grade': {
    image: '@/client/assets/images/show-discus.jpg',
    title: 'Show Ready Discus',
    subtitle: 'Selection from our personal show winning pairs',
    type: 'show grade',
    specialty: 'discus',
    price: '180 - 350+',
  },
  'personal tank': {
    image: '@/client/assets/images/medium-discus.jpg',
    title: 'Personal Tank Beauties',
    subtitle: 'High quality from show winning offspring',
    type: 'personal tank',
    specialty: 'discus',
    price: '120',
  },
  wholesale: {
    image: '@/client/assets/images/common-discus.jpg',
    title: 'Beautiful Discus at Wholesale Prices',
    subtitle: 'Selection of high quality common Discus',
    type: 'wholesale',
    specialty: 'discus',
    price: '20-60',
  },
  'entry level': {
    image: '@/client/assets/images/aquascape-2-small.jpg',
    title: 'Entry Level Scapes',
    subtitle: 'Aquascapes setups perfect for beginners',
    type: 'entry level',
    specialty: 'aquascapes',
    price: '100 - 250+',
  },
  'discus scape': {
    image: '@/client/assets/images/discus.jpg',
    title: 'Discus Aquascapes',
    subtitle: 'Simple, minimal, and beautiful aquascapes for Discus aquariums',
    type: 'discus scape',
    specialty: 'aquascapes',
    price: '120 - 300+',
  },
  'nature aquascape': {
    image: '@/client/assets/images/aquascape-3.jpg',
    title: 'Nature Aquascapes',
    subtitle: 'Aquascapes for those who love nature',
    type: 'nature aquascape',
    specialty: 'aquascapes',
    price: '150-300+',
  },
  bowls: {
    image: '@/client/assets/images/desktop-nanoscape.jpg',
    title: 'Nature Bowls',
    subtitle: 'Microscapes for those who love nature',
    type: 'bowls',
    specialty: 'microscapes',
    price: '100 - 250',
  },
  ponds: {
    image: '@/client/assets/images/microscape-desktop.jpg',
    title: 'Desktop Pond',
    subtitle: 'A small pond to enjoy in any area',
    type: 'ponds',
    specialty: 'microscapes',
    price: '80 - 150',
  },
  natural: {
    image: '@/client/assets/images/desktop-nanoscape.jpg',
    title: 'Natural Microscape',
    subtitle: 'Microscapes for those who love nature',
    type: 'natural',
    specialty: 'microscapes',
    price: '100-200',
  },
};

export const allContent = {
  landing: {},
  about: aboutContent,
  aquascapes: aquascapeContent,
  discus: discusContent,
  microscapes: microscapeContent,
  shrimp: shrimpContent,
} as const;
