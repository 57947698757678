import Vue from 'vue';
import Router from 'vue-router';
import Landing from './client/components/Landing.vue';
import About from './client/components/About.vue';
import GetStarted from './client/components/GetStarted.vue';
import Terms from './client/components/Terms.vue';
import Shrimp from './client/components/specialties/Shrimp.vue';
import Aquascapes from './client/components/specialties/Aquascapes.vue';
import Discus from './client/components/specialties/Discus.vue';
import Microscapes from './client/components/specialties/Microscapes.vue';
import NotFound from './client/components/NotFound.vue';
import Cost from './client/components/Cost.vue';
import Specialties from './client/components/specialties/Specialties.vue';

Vue.use(Router);

const routeArray = [
  {
    path: '/',
    name: 'welcome',
    component: Landing,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/specialties',
    name: 'specialties',
    redirect: '/specialties/shrimp',
    component: Specialties,
    children: [
      {
        path: 'shrimp',
        name: 'shrimp',
        component: Shrimp,
      },
      {
        path: 'discus',
        name: 'discus',
        component: Discus,
      },
      {
        path: 'aquascapes',
        name: 'aquascapes',
        component: Aquascapes,
      },
      {
        path: 'microscapes',
        name: 'microscapes',
        component: Microscapes,
      },
    ],
  },
  {
    path: '/get-started',
    name: 'start',
    component: GetStarted,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '*',
    redirect: {
      name: 'not-found',
    },
  },
];

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior(to, from) {
    return new Promise((resolve: Function) => {
      setTimeout(() => {
        if (to.name !== from.name) {
          resolve(window.scrollTo({ top: 0, behavior: 'smooth' }));
        } else {
          resolve();
        }
      }, 300);
    });
  },
  routes: routeArray,
});

export default router;
